@import 'node_modules/wonderland/src/utilities';

$font-display: 'Pangea Display', sans-serif !default;
$font-sans: 'Pangea Text', sans-serif !default;
$font-serif: 'Merriweather', serif !default;
$text-brand: map-get($palette, 'blue-500');
$font-mono: 'Apercu Mono', 'Courier New', Courier, monospace !default;
$font-tusker: 'Tusker Grotesk', sans-serif !default;

html,
body {
  font-family: $font-sans, Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.font-display {
  font-family: $font-display;
}

.font-tusker {
  font-family: $font-tusker;
}

ul {
  list-style-type: disc;
  margin-left: rem(16);
}

.list-none {
  list-style-type: none;
  margin-left: 0;
}

.font-sans {
  font-family: $font-sans;
}

.font-serif {
  font-family: $font-serif;
}

.font-mono {
  font-family: $font-mono;
}

.font-light,
.font-normal {
  font-weight: 300;
}

.font-semibold {
  font-weight: 500;
}

.font-bold {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-display;
  font-weight: 500;
  line-height: 1.1;
}

a {
  color: inherit;
}

b,
strong {
  font-weight: 700;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

input {
  font-weight: 300;
}

.container {
  $padding: 24px;

  margin: {
    left: auto;
    right: auto;
  }

  max-width: map-get($breakpoints, 'xl');

  padding: {
    left: rem($padding);
    right: rem($padding);
  }

  position: relative;
  width: 100%;

  @media only screen and (min-width: map-get($breakpoints, 'sm')) {
    padding: {
      left: rem($padding*2);
      right: rem($padding*2);
    }
  }
}

h2.separator {
  position: relative;

  &:after {
    background: map-get($palette, 'blue-200');
    bottom: 0;
    content: '';
    display: block;
    height: rem(4);
    left: 25.5%;
    position: absolute;
    width: 50%;
  }
}

.link {
  border: {
    bottom-width: 1px;
    color: transparent;
  }

  transition: {
    duration: 150ms;
    property: all;
    timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  &:hover {
    border-color: currentColor;
  }

  &-text {
    border-color: currentColor;
  }
}

p>a {
  color: $text-brand;
  text-decoration: none;

  &:hover {
    border-bottom: 2px solid $text-brand;
  }
}

.sticky-menu {
  backface-visibility: hidden;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  transition: all .5s cubic-bezier(.77, 0, .175, 1);
  width: 100%;
  z-index: 999;
}

.is-sticked {
  transform: translateY(0);
}

.dropcaps {
  &>p {
    &:first-of-type {
      &:first-letter {
        display: block;
        float: left;
        font-size: 6em;
        margin-top: .08em;
        padding-right: .05em;
        text-transform: uppercase;
      }
    }
  }
}

.shadow-before {
  &:before {
    background: linear-gradient(180deg, hsla(0, 0%, 7%, .3), hsla(0, 0%, 7%, 0));
    content: "";
    height: 40%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

.shadow-after {
  &:after {
    background: linear-gradient(180deg, hsla(0, 0%, 7%, 0), hsla(0, 0%, 7%, .3));
    content: "";
    height: 20%;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }
}

.page-overview {
  --block-height: 49px;
  --container-width: calc(min(100%, 1280px));
  --container-margins: calc((100vw - var(--container-width)) / 2);
  --percent: calc(11 / 12);
  background: #fff;
  display: block;
  height: var(--block-height);
  left: 0;
  position: absolute;
  top: 1px;
  transform: translateX(calc(var(--container-margins) * -1));
  width: calc((var(--container-width) * var(--percent)) + var(--container-margins));
  z-index: 0;

  @media only screen and (min-width: map-get($breakpoints, 'md')) {
    --percent: calc(10 / 12);
    --block-height: 112px;
    top: 0;
  }

  @media only screen and (min-width: map-get($breakpoints, 'lg')) {
    --percent: calc(8 / 12);
    --block-height: 112px;
  }
}

@mixin show-element {
  --translate-y: 0;
  box-shadow: 0 3rem 9rem rgba(0, 0, 0, .7);
  display: block;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  z-index: 20;
}

.navbar {
  $this: &;
  z-index: 999;

  &__logo,
  &__item,
  &__search,
  &__hamburger {
    color: white;
  }

  &__solid {
    background-color: white;
    border: 1px solid map-get($palette, 'black-200');

    #{$this} {
      &__logo {
        color: $text-brand;
      }

      &__item,
      &__search,
      &__hamburger {
        color: map-get($palette, 'black-500');
      }

    }
  }

  &__dark {
    background-color: transparent;

    #{$this} {
      &__logo {
        color: $text-brand;
      }

      &__item,
      &__search,
      &__hamburger {
        color: map-get($palette, 'black-500');
      }

    }
  }

  &__clear {
    background-color: transparent;

    #{$this} {

      &__logo,
      &__item,
      &__search,
      &__hamburger {
        color: white;
      }
    }
  }

  &__submenu {
    --animation-duration: 400ms;
    box-shadow: 0 rem(-16) rem(32) transparent;
    transition: opacity var(--animation-duration), transform var(--animation-duration), box-shadow 1.2s, visibility var(--animation-duration);

    &:before {
      border: 8px solid transparent;
      border-bottom-color: #fff;
      content: "";
      display: block;
      height: 0;
      left: 50%;
      margin-left: -8px;
      position: absolute;
      top: -16px;
      width: 0;
    }
  }

  &__item {
    &:hover {
      &>#{$this}__submenu {
        @include show-element;
      }
    }

    &:last-child {
      &>#{$this}__submenu {
        transform: translateX(-85%);

        &:before {
          left: auto;
          right: rem(40);
        }
      }
    }
  }
}

.wysiwyg {
  p {
    margin-bottom: rem(16);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.lpbutton {
  background-color: $text-brand;
  border: 1px solid $text-brand;
  border-radius: 9999px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: .75rem 1.25rem;
  transition-duration: .4s;
  transition-property: color, background-color, border;
  transition-timing-function: ease-in-out;
  white-space: nowrap;

  &-mini {
    font-size: 12px;
    padding: .75rem 1.2rem;
  }

  &-xs {
    font-size: 10px;
    padding: .5rem .9rem;
  }

  &-white {
    background-color: #fff;
    border: 1px solid map-get($palette, 'black-200');
    color: map-get($palette, 'black');
    text-transform: none;

    &:hover {
      background-color: $text-brand;
      border-color: $text-brand;
      color: white;
    }
  }

  &-orange {
    background-color: #eb6e38;
    border: 1px solid #eb6e38;
    color: #fff;

    &:hover {
      background-color: #eb6e38;
      border-color: #eb6e38;
      color: white;
    }
  }

  &-trans {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    text-transform: none;

    &:hover {
      background-color: $text-brand;
      border-color: $text-brand;
      color: white;
    }
  }

  &-disabled {
    background-color: #fff;
    border: 1px solid map-get($palette, 'black-200');
    color: map-get($palette, 'black');
    cursor: not-allowed;
    opacity: .5;
    text-transform: none;
  }

  &-outline {
    background-color: #fff;
    border: 2px solid $text-brand;
    color: $text-brand;
    text-transform: none;

    &-trans {
      background-color: transparent;
    }

    &:hover {
      background-color: $text-brand;
      border-color: $text-brand;
      color: white;
    }
  }
}

.btn {
  border-radius: 9999px;
  border: 1px solid map-get($palette, 'black-500') !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  overflow: hidden;
  padding: rem(8) rem(20);
  text-align: center;
  text-overflow: ellipsis;
  transition: all 150ms ease-in-out;
  white-space: nowrap;

  &:hover {
    border-color: map-get($palette, 'blue-500') !important;
    background-color: map-get($palette, 'blue-500');
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, .1) 0 1px 3px 0, rgba(0, 0, 0, 0.1) 0 1px 2px -1px;
    color: white;
  }

  &-flat {
    border: 1px solid rgb(255, 255, 255) !important;
  }
}

.from-transparent {
  --gradient-from: transparent;
  --gradient-stops: var(--gradient-from), var(--gradient-to, transparent);
}

.min-h-100vh {
  min-height: 100vh;
}

html.sr .load-hidden {
  visibility: hidden;
}

.decimal-ul {
  list-style-type: decimal;
  padding-left: 2em;
}

.dotted-ul {
  list-style-type: disc;
  padding-left: 1em;

  @media only screen and (min-width: map-get($breakpoints, 'lg')) {
    padding-left: 2em;
  }
}

.gradient {
  background-clip: text;
  background-color: map-get($palette, 'black-500');
  background-image: linear-gradient(90deg, map-get($palette, 'black-500'), map-get($palette, 'black-300'));
  color: transparent;
  will-change: transform;
  overflow: hidden;
}

.rounded-window {
  border-radius: 100000px;
}

.mouse-arrow {
  animation-name: scroll;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(.15, .41, .69, .94);
  animation-iteration-count: infinite;
}

.animation-delay {
  animation-delay: .15s;
}

@keyframes scroll {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  80% {
    transform: translateY(10px);
    opacity: 1;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

.max-width-screen {
  max-width: 100% !important;
}

.hero-corners {
  border-radius: 0;

  @media only screen and (min-width: map-get($breakpoints, 'lg')) {
    border-radius: 24px;
  }
}

.hero-module {
  &:before {
    background: linear-gradient(180deg, hsla(0, 0, 7%, .5), hsla(0, 0, 7%, 0));
    content: "";
    height: 33%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.blurred {
  filter: blur(4px)
}

.prose {

  p {
    margin-bottom: 8px;
  }

  p+h3 {
    padding-top: 32px;
  }

  h3+h4 {
    padding-top: 8px;
  }

  p+h4 {
    padding-top: 20px;
  }

  ul {
    li {
      margin-bottom: 6px;
    }
  }

  ul {
    margin-bottom: 24px;
  }

  ul+h3 {
    padding-top: 12px;
  }

  ul+p {
    margin-bottom: 8px;
  }

  hr {
    height: 0;
    border-top-width: 1px;
  }
}

// OLD LP Styles override
.navbar {
  border: 0 !important;
}

.primary-nav__shadow::after {
  box-shadow: none !important;
}

.globalnav__logo {
  margin: 0 auto !important;
  padding-left: 20px !important;

  svg {
    width: 100% !important;
  }
}

.globalnav__container {
  border: 0 !important;
}

.page-template--edge {
  .navbar__search {
    color: map-get($palette, 'black-500');
  }

  .globalnav__logo {
    color: $text-brand;
  }

  .globalnav__hamburger {
    color: map-get($palette, 'black-500');
  }
}

.breakpoint {
  &::before {
    background-color: map-get($palette, 'black-500');
    color: #fff;
    font-size: rem(12);
    padding: rem(12) rem(16);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2147483647;
  }
}

.text-oversize {
  font-size: 600%;
  letter-spacing: -2px;
  line-height: 1;

  @media only screen and (min-width: map-get($breakpoints, 'sm')) {
    font-size: 800%;
  }

  @media only screen and (min-width: map-get($breakpoints, 'md')) {
    font-size: 1000%;
  }

  @media only screen and (min-width: map-get($breakpoints, 'lg')) {
    font-size: 1200%;
  }
}

@media only screen and (min-width: map-get($breakpoints, 'lg')) {
  .lg\:text-oversize {
    font-size: 1200%;
    letter-spacing: -2px;
    line-height: .8;
  }
}

.bg-card-shadow {
  background-image: linear-gradient(0deg, hsla(0, 0%, 7%, .5), hsla(0, 0%, 7%, .4) 51%, hsla(0, 0%, 7%, 0));
  background-position: 0 80%;
  background-size: 200% 200%;
}

.max-content {
  width: -moz-max-content;
  width: max-content;
}

.border-3 {
  border-width: 3px;
}

.border-t-3 {
  border-top-width: 3px;
}

.border-b-3 {
  border-bottom-width: 3px;
}

.bg-yellow,
.hover\:bg-yellow:hover,
.focus\:bg-yellow:focus {
  background-color: rgb(250, 245, 148);
}

.bg-topogradient {
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-image: url('https://lonelyplanet--media.s3-eu-west-1.amazonaws.com/public/layout/topo.png'), linear-gradient(180deg, #374559, #563753);
}

.bg-clip-text {
  background-clip: text;
}

.from-slate {
  --gradient-from: rgba(55, 69, 89, 100);
  --gradient-stops: var(--gradient-from), var(--gradient-to, rgba(55, 69, 89, 0));
}

.to-salmon {
  --gradient-to: rgba(227, 113, 108, 100);
}

.max-w-screen {
  max-width: 100vw !important;
}

.rotate-neg90 {
  --rotate: -90deg;
}

.z-top {
  z-index: 9999;
}

:root {
  --scrollbar-width: 0
}

.w-scroll {
  max-width: 100%;
  width: calc(100vw - var(--scrollbar-width));
}

.flex-2 {
  flex: 2 2 0%;
}

.cta {
  align-items: center;
  background-color: white;
  border-radius: 9999px;
  border: 1px solid map-get($palette, 'black-300');
  box-sizing: border-box;
  color: map-get($palette, 'black-400');
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  line-height: 1;
  min-height: 38px;
  padding: .65rem 1.25rem;
  position: relative;
  font-size: 13px;
  font-weight: 500;
  font-family: $font-display;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
  white-space: nowrap;

  &:focus,
  &:hover {
    background-color: map-get($palette, 'blue-500');
    border-color: map-get($palette, 'blue-500');
    color: white;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  &--primary {
    background-color: map-get($palette, 'blue-500');
    border-color: map-get($palette, 'blue-500');
    color: white;

    &:focus,
    &:hover {
      background-color: map-get($palette, 'blue-400');
      border-color: map-get($palette, 'blue-400');
    }
  }

  &--sm {
    font-size: 12px;
    padding: .5rem 1rem;
    min-height: 24px;
  }

  &--trans {
    background-color: transparent;
  }

  &--square {
    padding: .35rem;
    aspect-ratio: 1 / 1;
    min-height: 1px;
  }
}

.group:hover .group-hover\:underline {
  text-decoration: underline;
}

.pre-screen {
  opacity: 0;
  visibility: hidden;
  background-color: hsla(0, 0, 7%, .6);
  backdrop-filter: blur(5px);

  &.is-showing {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease, visibility 0s
  }
}

.grid-cols-auto-1 {
  grid-template-columns: auto minmax(0, 1fr);
}

.bg-yellow-100,
.hover\:bg-yellow-100:hover,
.focus\:bg-yellow-100:focus {
  background-color: rgb(251, 247, 236);
}


@media (prefers-reduced-motion) {
  .pre-screen {
    opacity: 0;
    visibility: hidden;

    &.is-showing {
      opacity: 1;
      visibility: visible;
    }
  }
}

.close-button {
  transform: translateY(-200%);

  &.is-showing {
    transform: translateY(0);
    transition: .5s;
  }
}

.z-20 {
  z-index: 20;
}

.z-forward {
  z-index: 5000;
}

.z-behind {
  z-index: -10000;
}

.lg\:text-white {
  @media only screen and (min-width: map-get($breakpoints, 'lg')) {
    --text-opacity: 1;
    color: rgba(255, 255, 255, var(--text-opacity));
  }
}

.visuallyhidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}